import styled from 'styled-components'
import Content from 'components/shared/Content'

export const NewsTitle = styled(Content)`
  & h2 {
    font-size: ${(props) => props.theme.font.size['40']};
    text-transform: uppercase;

    & .highlighted {
      font-size: ${(props) => props.theme.font.size.l};
      font-family: 'Cardo', sans-serif;
      font-weight: 100;
      /* font-style: italic; */
      text-transform: none;
    }
  }
`
