import React from 'react'
import { useMedia } from 'react-use'

function Window({ imageUrl }) {
  const isDesktop = useMedia('(min-width: 992px)')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="329"
      height="632"
      viewBox="0 0 329 632"
    >
      <defs>
        <clipPath id="a">
          <path
            fill="#d8d8d8"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="15"
            d="M0 617V157a157 157 0 01314 0v460z"
          />
        </clipPath>
      </defs>
      {isDesktop ? (
        <path
          className="window-border"
          fill="#d8d8d8"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="15"
          d="M0 617V157a157 157 0 01314 0v460z"
          transform="translate(7.5 7.5)"
        />
      ) : (
        <path
          className="window-border"
          fill="#d8d8d8"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="15"
          d="M0 617V157a157 157 0 01314 0v460z"
          transform="translate(7.5 7.5)"
        />
      )}

      <g clipPath="url(#a)" transform="translate(7.5 7.5)">
        <image
          width="487"
          height="638"
          transform="translate(-92 -21)"
          xlinkHref={imageUrl}
        />
      </g>
    </svg>
  )
}

export default Window
